<template>
    <div v-loading="loading" class="home-box">
        <el-page-header @back="goBack" :content="pageTitle">
        </el-page-header>
        <!-- 查询区域 -->
        
        <!-- 新闻公告内容 -->
        <div class="text_content">
          <h1 class="h1">{{ noticeInfo.title }}</h1>
          <h2 class="h2">更新时间：{{ noticeInfo.createdate }}</h2>
          <div class="rich_text" v-html="noticeInfo.content"></div>
        </div>
    </div>
  </template>
    
    <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  
  export default Vue.extend({
    name: "ordersDetails",
    data() {
      return {
        pageTitle: '公告详情',
        noticeInfo: {},
        loading: false,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    created() {
      if (this.$route.query.id) {
        this.getNoticeInfo(this.$route.query.id)
      } else {
        this.goBack()
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      goBack() {
        this.$router.back()
      },
      // 获取公告详情
      getNoticeInfo(id) {
        this.loading = true
        this.$api.getNoticeInfo({id}).then(
          res => {
            if(res.code == 'success'){
              this.noticeInfo = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
    
  <style scoped lang="less">
  @import "../../assets/less/easyElement.less";

  .text_content{
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: center;
  }
  .h1{
    width: 100%;
    font-size: 36px;
    margin-top: 12px;
    font-weight: 600;
  }
  .h2{
    width: 100%;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: 200;
  }
  .rich_text{
    width: 100%;
    font-size: 20px;
    font-weight: 200;
  }
  </style>
    